import React from "react"
import { Link, useI18next } from "gatsby-plugin-react-i18next"

export default function EventCard({ event }) {
  const { coverImage, title, excerpt, slug } = event
  const coverImageUrl = coverImage.url
  const { language } = useI18next()
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  }
  const eventDate = new Date(event.date)
  var pathPrefix = "/events/"

  return (
    <div
      className="card"
      style={{
        boxShadow:
          "0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0px 0 1px rgb(10 10 10 / 15%)",
        height: "100%",
      }}
    >
      <Link to={pathPrefix + slug}>
        <div className="card-image">
          <figure className="image is-4by3">
            <img src={coverImageUrl} alt={title} />
          </figure>
        </div>
      </Link>
      <div className="card-content block">
        <div className="media-content">
          <div style={{ minHeight: "110px" }}>
            <Link to={pathPrefix + slug}>
              <p className="title is-4">{title}</p>
            </Link>
            <hr />
          </div>
          <div>
            <span className="bi bi-geo-alt has-text-danger pr-2"></span>
            <strong>{event.address}</strong>
            <div>
              <time dateTime={eventDate.toISOString()} className="is-italic">
                <p>
                  <span className="bi bi-calendar4-event has-text-link pr-2"></span>
                  {eventDate.toLocaleDateString(language, options)}
                </p>
                <p>
                  <span className="bi bi-clock has-text-link pr-2"></span>
                  {eventDate.toLocaleTimeString(language, {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </p>
              </time>
              <time
                dateTime={eventDate.toISOString()}
                className="is-italic"
              ></time>
            </div>
            <hr />
          </div>
        </div>
        <div className="content mt-4" style={{ minHeight: "120px" }}>
          {excerpt}
        </div>
      </div>
    </div>
  )
}
